export default {
    // SITE_URL: 'http://localhost:3000/',
    // SITE_URL: 'https://stage.itweb.co.za/',
    cdn: 'https://www.itweb.co.za/api',
    SITE_URL: 'https://www.itweb.co.za/',
    AFRICA_SITE_URL: 'https://itweb.africa/',
    MG_SITE_URL: 'https://pressoffice.mg.co.za/',
    URL: 'https://api.itweb.co.za/', // webpack.config.js
    // URL: 'https://stageapi.itweb.co.za/',
    //URL: 'http://localhost:8000/',
    STORAGE_URL: 'https://api.itweb.co.za/',
    client_id: 2,
    client_secret: 'bz80P5wNt4iEhnsHMANm8MbUxlFqQrvBnYYyFfRv'
}
